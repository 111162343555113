body {
    margin: 0px;
    background-color: #F8F8FF;
}

a {
    text-decoration: none;
    color: inherit;
}

.body-container {
    width: 100%;
}

.top-nav-main {
    position: relative;
    min-height: 50px;
    max-height: 400px;
    height: 120px;
    background-color: #090909;
    padding: 0px 20px;
}

.top-nav-container {    
    max-width: 800px;
    height: inherit;
    margin: 0px auto 0px auto;
}

.main-logo-container {    
    height: inherit;
    float: left;
}

.main-logo-container a {
    height: inherit;
    display: flex;
    align-items: center;
    outline: none;
}

.main-logo {
    position: relative;
    height: 60%;
    cursor: pointer;
}

.right-header-nav {
    float: right;
    display: flex;
    align-items: center;
    height: 100%;
    overflow: hidden;
}

.nav-main {
    display: block;
}

.nav-dropdown {
    display: none;
}

.right-header-nav ul {
    list-style-type: none;
}

.right-header-nav li {
    display: inline;
    color: #F8F8FF;
    margin: 0px 1.3vw 0px;
    font-family: "Courier Prime Code", Courier, monospace;
}

.right-header-nav.active {
    color: #41C5F9;
}

.right-header-nav li {
    cursor: pointer;
}

.right-header-nav a:hover, a:focus {
    color: #FAA916;
}

.nav-dropdown-act {
    cursor: default;
    color: #F8F8FF;
    padding: 0px 0px 0px 85px;
}

.nav-drop-content {    
    opacity: 0;
    transition: 1s all;
    position: fixed;
    background-color: #41C5F9;
    min-width: 100px;
    margin: 10px 0px 0px;
    padding: 10px 5px 10px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: -1;
    overflow: visible;
    text-align: right;
}

.nav-drop-content ul {
    margin: 0px;
    padding: 0px;    
}

.nav-drop-content ul li {
    display: list-item;
    margin: 10px;
}

.nav-drop-content span {
    cursor: pointer;
}

.nav-dropdown:hover .nav-drop-content {
    z-index: 2;
    opacity: 1;
    transition: 0.3s all;
}

.nav-drop-content:focus-within {
    z-index: 2;
    opacity: 1;
    transition: 0.3s all;
}

.nav-link {
    padding: 10px 10px;
    outline: none;
}

.nav-link.active {
    border-bottom: 4px solid #41C5F9;
    // background-color: #41C5F9;
    // border-radius: 5px;
}

.nav-link:focus .nav-drop-content {
    z-index: 2;
    opacity: 1;
    transition: 0.3s all;    
}

.body-main {
    min-height: 85vh;
    padding: 0px 20px;
}

.body-main-content {
    display: flex;
    padding: 100px 0px;
    font-family: "Courier Prime Code", Courier, monospace;
    max-width: 800px;
    margin: 0px auto;
}

.body-main-left {
    display: inline;
    position: relative;
    width: min-content;
    margin: 0px auto 0px 0px;
}

.welcome-preamble {
    font-size: 16px;
    margin: 0px 0px 20px 5px;
}

.welcome-main {
    word-spacing: 999999px;
    font-size: 80px;
}

.about-left {
    display: inline;
    position: relative;
    float: left;
    width: auto;
    margin: 0px 0px 25px;
}

.about-right {
    display: inline;
    position: relative;
    float: left;
}

.about-right-line {
    margin: 0px 0px 23px;
    display: block;
    vertical-align: top;
}

.rainbow-list {
    -moz-transition-property:background-color;
    -moz-transition-timing-function:ease-out;
    -moz-transition-duration:2.0s;
    -webkit-transition-property:background-color;
    -webkit-transition-timing-function:ease-out;
    -webkit-transition-duration:2.0s;
    -o-transition-property:background-color;
    -o-transition-timing-function:ease-out;
    -o-transition-duration:2.0s;
    transition-property:background-color;
    transition-timing-function:ease-out;
    transition-duration:2.0s;
}

.resume-main {
    display: flex;
}

.resume-main-content {
    padding: 50px 0px 0px;
    display: block;
    height: auto;
}

.resume-main-content li {
    list-style: none;
}

.resume-main-content h2 {
    margin: 0px 0px 5px;
    color: #F8F8FF;
    background-color: #045677;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 0px 0px 15px;
}

.resume-main-content h3 {
    margin: 15px 0px 2px;
    color: #1E7B9F;
}

.resume-main-content h2+h3 {
    margin: -5px 0px 2px;
}

.resume-main-content h4 {
    margin: 0px 0px 2px;
    color: #090909;
}

.resume-main-content p {
    margin: 0px 0px 10px;
    font-family: "EB Garamond", Times, serif;
    font-size: 14pt;
    color: #090909;
}

.resume-section {
    margin: 0px 0px 20px;
}

.resume-header {
    display: flex;
    position: relative;
    height: 50px;
    margin: 0px 0px 15px;
    align-items: center;
}

.resume-header div {
    display: inline-block;
    position: absolute;
}

.resume-header-name {
    display: inline;
    text-align: center;
    left: 0%;
    right: 0%;
}

.resume-header h1 {
    display: inline;
    font-size: 36px;
    margin: 0px 0px 15px;
    color: #090909;
    font-weight: normal;
    left: 0%;
    right: 0%;
}

.resume-contact {
    display: none;
}

.resume-download {
    position: relative;
    display: inline;
    text-align: right;
    right: 0px;
}

.resume-download span:hover {
    color: #FAA916;
}

.resume-main-left {
    width: 55%;
    text-align: right;
}

.resume-main-right {
    width: 40%;
}

.resume-date p{
    margin: 0px;
    font-style: italic;
    font-size: 12pt;
}

.contact-main-right {
    display: flex;
}

.contact-main {
    display: flex;
    align-items: center;
    height: auto;
    width: 325px;
    background-color: #1E7B9F;
    padding: 15px 25px;
    margin: auto 0px;
    color: #F8F8FF;
    border-style: solid;
    border-color: #1E7B9F;
    border-radius: 5px;
    box-shadow: 8px 16px 24px 0px rgba(0,0,0,0.2);
}

.contact-main:hover {
    border-color: #FAA916;
}

.contact-main a {
    outline: none;
}

.contact-main a:hover {
    color: #FAA916;
}

.contact-left {
    height: inherit;
    margin: auto 10px auto 0px;
    float: left;
}

.headshot img {
    display: inline;
    width: 87.5px;
}

.contact-intro {
    font-size: 24px;
}

.contact-info {
    display: grid;
    line-height: 2;
    margin: 0px 0px 0px auto;
}

.contact-info-line {
    margin: 0px 0px 0px auto;
}

.contact-info-line a {
    display: flex;
    align-items: center;
}

.contact-info span.im {
    margin: 0px 8px 0px 0px;
    font-size: 16px;
}

.footer-main {
    display: flex;
    width: 100%;
    height: 50px;
    background-color: #090909;
    color: #F8F8FF;
    font-size: 16px;
    font-family: "Courier Prime Code", Courier, monospace;
    align-items: center;
}

.footer-content {
    margin: 0px auto;
}

.right-header-nav .nav-item-blog {
    display: none;
}

/* MOBILE RESPONSIVE */

@media screen and (max-width: 480px) {
    .top-nav-main {
	height: 60px;
    }
    
    .nav-main {
	display: none;
    }

    .nav-dropdown {
	display: block;
    }
    
    .body-main-content {
	padding: 10px 0px;
	display: grid;
    }

    .body-main-left {
	display: block;
	float: none;
	width: 100%;
	border-bottom: 3px solid #090909;
	margin: 0px 0px 30px 0px;
    }
    
    .body-main-right {
	display: block;
	float: none;
	width: auto;
    }

    .welcome-preamble {
	display: block;
	text-align: center;
	margin: 15px 0px;
    }

    .welcome-main {
	display: inline;
	font-size: 48px;
	word-spacing: normal;

    }

    .resume-header h1 {
	font-size: 18pt;
    }

    .resume-main {
	display: block;
    }

    .resume-main-left {
	text-align: left;
    }

    .body-main-left.contact {
	display: none;
    }

    .contact-main {
	margin: 15px 0px;
	width: auto;
	display: block;
    }

    .contact-left {
	display: block;
	float: none;
	width: 100%;
	margin: 0px;
	text-align: center;
    }

    .contact-info {
	display: block;
	float: none;
	text-align: left;
	margin: 0px;
    }

    .contact-intro {
	text-align: center;
    }

    .contact-info-line {
	text-align: center;
    }

    .contact-main a:hover {
	color: #F8F8FF;
    }
}

@media print {
    body {
	background-color: #FFFFFF;
    }

    .resume-header {
	margin: 0px;
    }

    .resume-contact {
	display: block;
	text-align: center;
	margin: 0px 0px 20px 0px;
    }

    .resume-contact-email {
	margin: 0px 10px 0px 0px;
    }
    
    .resume-contact-phone {
	margin: 0px 0px 0px 10px;
    }

    .resume-main-content h2 {
	font-size: 21pt;
    }

    .resume-main-content h3 {
	font-size: 18pt;
    }

    .resume-main-content h4 {
	font-size: 16pt;
    }

    .resume-main-content p {
	font-size: 13pt;
    }
}
